import React from "react"
import Content from "../components/Content"
import HeroImage from "../components/HeroImage"
import Layout from "../components/Layout"
import heimordnung from "../files/Heimordnung.pdf"
import datenschutz from "../files/Datenschutz.pdf"
import anmeldung from "../files/Anmeldung.pdf"
import fitnessraum from "../files/Fitnessraum.pdf"
import Helmet from "react-helmet"

export default function Downloads() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Downloads — Schülerwohnheim Forster GmbH</title>
      </Helmet>
      <HeroImage color="#ef2d2d" title="Downloads" />
      <Content bgColor="#f3898a">
        <a href={heimordnung} target="_blank">
          Heimordnung
        </a>
        <br />
        <a href={datenschutz} target="_blank">
          Datenschutz
        </a>
        <br />
        <a href={anmeldung} target="_blank">
          Anmeldung
        </a>
        <br />
        <a href={fitnessraum} target="_blank">
          Fitnessraum
        </a>
      </Content>
    </Layout>
  )
}
